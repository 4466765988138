import Vue from 'vue'
import Router from 'vue-router'


const MainPage = () => import('./components/MainPage.vue')
const Home = () => import('./components/Home.vue')
const Project = () => import('./components/Projects.vue')
const Description = () => import('./components/Description.vue')
const Location = () => import('./components/Location.vue')
const Contact = () => import('./components/contact.vue')
const View = () => import('./components/View.vue')
const BlueprintFloor = () => import('./components/BlueprintFloor.vue')
const BlueprintFlat = () => import('./components/BlueprintFlat.vue')
const Datenschutz = () => import('./components/Datenschutz.vue')
const Impressum = () => import('./components/Impressum.vue')
const Musterflat = () => import('./components/MusterFlat.vue')

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  linkActiveClass: "",
  linkExactActiveClass: "active",
  
  routes: [
    { path: '/', component: MainPage, meta: { title: 'Home' },
        children:[
            { path: '', component: Home, name:'home', meta: { title: 'Home' } },
            { path: '/project', component: Project, name:'project', meta: { title: 'Projekt' },
            children:[
                {path: 'description', component: Description, name:'description', meta: { title: 'Beschreibung' } },
                {path: 'location', component: Location, name:'location', meta: { title: 'Lage' } },
                {path: 'view', component: View, name:'view', meta: { title: 'Ansicht' } },
                {path: 'blueprint-floor', component: BlueprintFloor, name:'blueprintFloor', meta: { title: 'Bauplan Etage' } },
                {path: 'blueprint-flat', component: BlueprintFlat, name:'blueprintFlat', meta: { title: 'Bauplan Wohnung' } },
                {path: 'muster-flat', component: Musterflat, name:'musterFlat', meta: { title: 'Musterwohnung' } },
                {path: 'contact/:id', component: Contact, name:'contact', meta: { title: 'Kontakt' } },
              ]
            },
            { path: '/datenschutz', component: Datenschutz, name:'datenschutz', meta : { title: 'Datenschutz' } },
            { path: '/impressum', component: Impressum, name:'impressum', meta : { title: 'Impressum' } },
        ] 
    },
    
    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
});


router.beforeEach((to, from, next) => {
  document.title = 'JV-Living | '+ to.meta.title
  next();
})
