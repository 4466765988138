<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
#app {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.75rem;
  line-height: 1.7;
  font-weight: 300;
  flex: 1 0 auto;
  max-width: 100%;
}
.page-main {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.container{
  max-width: 1600px !important;
}
</style>
