import Vue from 'vue'
import App from './App.vue'
import './styles.scss'
import { router } from './router'
// import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

Vue.config.productionTip = false

import {
  CarouselPlugin,
  CollapsePlugin ,
  DropdownPlugin, 
  NavPlugin, 
  NavbarPlugin,
  ButtonPlugin,
  FormPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  FormGroupPlugin,
  CardPlugin,
  FormSelectPlugin,
  ModalPlugin
} from 'bootstrap-vue'


// Bootstrap Vue
Vue.use(ModalPlugin)
Vue.use(FormSelectPlugin)
Vue.use(CarouselPlugin)
Vue.use(CardPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormPlugin)
Vue.use(ButtonPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)


new Vue({
  el: '#app',
  router:router,
  render: h => h(App),
});
